<template>
    <div>
        <h4>Manage demonstrations in groups</h4>

        <b-button variant="dark" block @click="showCreateGroupModal = true" class="mt-5">Create new group</b-button>
        <CreateGroupModal :show="showCreateGroupModal" @closeCreateGroupModal="closeCreateGroupModal" @groupCreated="groupCreated"></CreateGroupModal>

        <div class="divider"></div>

        <b-form-select
                v-model="selectedGroupId"
                :options="groups"
                value-field="id"
                text-field="name"
                @change="onChangeGroup"
        >
            <template #first>
                <b-form-select-option value="" disabled>-- Please select a group --</b-form-select-option>
            </template>
        </b-form-select>

        <b-form-group class="mt-3" v-if="isNotNull(selectedGroup)">
            <b-form-tags>
                <template>
                    <p class="mb-2">Demonstrations in group :</p>
                    <ul v-if="selectedDemonstrations.length > 0" class="list-inline mb-2">
                        <li v-for="demo in selectedDemonstrations" :key="'tags_demo_' + demo.id" class="list-inline-item">
                            <b-form-tag
                                    @remove="onRemoveDemo(demo)"
                                    variant="dark"
                            >
                                {{ demo.name }}
                            </b-form-tag>
                        </li>
                    </ul>
                    <span v-else>
                        No demonstration in this group for the moment
                    </span>

                    <b-dropdown size="sm" variant="outline-secondary" block menu-class="w-100" class="mt-2">
                        <template #button-content>
                            Choose demonstrations
                        </template>
                        <b-dropdown-form @submit.stop.prevent="() => {}">
                            <b-form-group
                                    label="Search demonstrations"
                                    label-cols-md="auto"
                                    class="mb-0"
                                    label-size="sm"
                                    :description="searchDesc"
                            >
                                <b-form-input
                                        v-model="search"
                                        type="search"
                                        size="sm"
                                        autocomplete="off"
                                ></b-form-input>
                            </b-form-group>
                        </b-dropdown-form>

                        <b-dropdown-divider></b-dropdown-divider>

                        <b-dropdown-item-button
                                v-for="demo in availableDemos"
                                :key="'dropdown_demo_' + demo.id"
                                @click="onDemoSelected(demo)"
                        >
                            {{ demo.name }}
                        </b-dropdown-item-button>

                        <b-dropdown-text v-if="availableDemos.length === 0">
                            There are no demonstrations available to select
                        </b-dropdown-text>
                    </b-dropdown>
                </template>
            </b-form-tags>
        </b-form-group>

        <b-button
                block
                variant="dark"
                class="mt-3"
                @click="saveList"
                v-if="isNotNull(selectedGroup)">
            Save selected demonstrations list
        </b-button>

        <b-alert :variant="message.variant" :show="isNotNull(message.content)" class="mt-3">{{ message.content }}</b-alert>

    </div>
</template>

<script>
	import DemonstrationsApi from "@/assets/js/DemonstrationsApi";
	import GroupsApi from "@/assets/js/GroupsApi";
	import {getConfig} from "@/assets/js/utils";
	import CreateGroupModal from "../components/CreateGroupModal";
	var app = getConfig();
	export default {
		name: "ManageDemonstrationsGroups",
		components: {CreateGroupModal},
		data() {
			return {
				demonstrations: [],
				groups: [],
				selectedGroupId: "",
				selectedGroup: null,
				selectedDemonstrations: [],
				message: {},
				search: '',
				showCreateGroupModal: false,
                createdGroupComplexId: null
			};
		},
		created(){
			this.retrieveDemonstrations();
			this.retrieveGroups();
		},
		computed: {
			availableDemos() {
				const criteria = this.search.trim().toLowerCase();
				const demos = this.demonstrations.filter(demo => {
					let index = this.selectedDemonstrations.findIndex(demoAvailable => demoAvailable.id === demo.id);
					return index === -1
				});
				if (this.isNotNull(criteria)) {
					let demoList = demos.filter(demo => demo.name.toLowerCase().indexOf(criteria) > -1);
					return demoList.sort(this.orderAsc)
				}
				return demos.sort(this.orderAsc)
			},
			searchDesc() {
				if (this.isNotNull(this.search) && this. availableDemos.length === 0) {
					return 'There are no demonstration matching your search'
				}
				return ''
			}
		},
		methods: {
			retrieveDemonstrations() {
				DemonstrationsApi.getAll()
					.then(response => {
						this.demonstrations = response.data;
					})
					.catch(e => {
						console.log(e);
					});
			},
			retrieveGroups() {
				GroupsApi.getAll()
					.then(response => {
						this.groups = response.data;
						this.groups.sort(this.orderAsc);
						let indexOfAdmin = this.groups.findIndex(group => group.complexId === app.groupComplexId);
						this.groups.splice(indexOfAdmin, 1);

						this.selectedGroup = this.groups.find(group => group.complexId === this.createdGroupComplexId);
						if(this.isNotNull(this.selectedGroup)){
							this.selectedGroupId = this.selectedGroup.id;
							this.selectedDemonstrations = this.selectedGroup.demonstrations;
                        }
					})
					.catch(e => {
						console.log(e);
					});
			},
			onChangeGroup(){
				this.selectedGroup = this.groups.find(group => group.id === this.selectedGroupId);
				this.checkAvailableDemos();
			},
			onDemoSelected(demo) {
				this.selectedDemonstrations.push(demo);
				this.search = ''
			},
			onRemoveDemo(demo){
				for(let i = 0; i < this.selectedDemonstrations.length; i++){
					if(this.selectedDemonstrations[i].id == demo.id){
						this.selectedDemonstrations.splice(i, 1);
						break;
                    }
                }
            },
			checkAvailableDemos(){
				if(this.isNotNull(this.selectedGroup)){
					this.selectedDemonstrations = this.selectedGroup.demonstrations
				}
			},
			saveList(){
				GroupsApi.editDemosList(this.selectedGroup, this.selectedDemonstrations)
					.then(response => {
						if(!response.data.error){
							this.message = {
								variant: "success",
								content: "Demonstrations list saved successfully"
							};
							setTimeout(() => {
								this.message.content = null
							}, 5000);
						}
					})
					.catch(e => {
						console.log(e);
						this.message = {
							variant: "danger",
							content: "Error while saving demonstrations"
						};
						setTimeout(() => {
							this.message.content = null
						}, 5000);
					});
			},
			orderAsc(a, b){
				if ( a.name.toLowerCase() < b.name.toLowerCase() ){
					return -1;
				}
				if ( a.name.toLowerCase() > b.name.toLowerCase() ){
					return 1;
				}
				return 0;
			},
			closeCreateGroupModal(){
				this.showCreateGroupModal = false
            },
            groupCreated(complexId){
				this.createdGroupComplexId = complexId;
				this.retrieveGroups()
				this.closeCreateGroupModal()
            }
		}
	};
</script>

<style>
</style>