var axios = require("axios");
import { getConfig } from "./utils";
var app = getConfig();

class GroupsApi{
    getAll(){
		let url = app.url_ws + "/group/all";
		return axios.get(url)
	}

    editDemosList(group, demoList){
		let url = app.url_ws + "/group/edit/fromgroup";

		let demos = "";
		demoList.forEach(demo => demos += demo.id + ",");
		demos = demos.slice(0, -1);

		let params = new FormData();
		params.append("name", group.name);
		params.append("demonstrationsIds", demos);
		params.append("complexId", group.complexId);

		return axios.post(url, params)
	}

    editGroupsList(demo, groupList){
		let url = app.url_ws + "/group/edit/fromdemo";

		let groupIds = "";
		groupList.forEach(group => groupIds += group.id + ",");
		groupIds = groupIds.slice(0, -1);

		let params = new FormData();
		params.append("demonstrationComplexId", demo.complexId);
		params.append("groupIds", groupIds);

		return axios.post(url, params)
	}

    create(name){
		let url = app.url_ws + "/group/add";

		let params = new FormData();
		params.append("name", name);

		return axios.post(url, params)
	}
}

export default new GroupsApi()