<template>
    <b-modal
            v-model="show"
            title="Create demonstrations group"
            centered
            ok-title="Create"
            ok-variant="dark"
            @ok="save"
            @cancel="close"
            @close="close"
    >
        <b-form-group
                label="Name of the group:"
                label-for="name"
        >
            <b-form-input
                    id="name"
                    v-model="name"
                    placeholder="Enter name"
                    required
            ></b-form-input>
        </b-form-group>

        <p>Groups can't be deleted after creation </p>

        <b-alert :variant="message.variant" :show="isNotNull(message.content)" class="mt-3">{{ message.content }}</b-alert>
    </b-modal>
</template>

<script>
	import GroupsApi from "@/assets/js/GroupsApi";
	export default {
		name: "CreateGroupModal",
		props: {
			show: Boolean,
		},
		data(){
			return {
				name: null,
				message: {},
			}
		},
		created(){
		},
		methods:{
			save(){
				GroupsApi.create(this.name)
					.then(response => {
						this.$emit('groupCreated', response.data.complexId)
					})
					.catch(e => {
						console.log(e);
						this.message = {
							variant: "danger",
							content: "Error while saving group"
						};
						setTimeout(() => {
							this.message.content = null
						}, 5000);
					});
			},
			close(event){
				event.preventDefault();
				this.$emit('closeCreateGroupModal')
            }
		}
	}
</script>

<style>
</style>